// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-mdx": () => import("./../src/pages/privacy-policy.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-mdx" */),
  "component---src-pages-return-policy-mdx": () => import("./../src/pages/return-policy.mdx" /* webpackChunkName: "component---src-pages-return-policy-mdx" */),
  "component---src-pages-terms-of-use-mdx": () => import("./../src/pages/terms-of-use.mdx" /* webpackChunkName: "component---src-pages-terms-of-use-mdx" */)
}

